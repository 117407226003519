import React from 'react';
import Layout from '../components/layout';
import styled from 'styled-components';
import SEO from '../components/seo';

const Block = styled.div`
  padding: 0 5vw;
  a {
    font-weight: 400;
    text-decoration: underline;
  }
`;

const Title = styled.h2`
  margin: 62px 0;
`;
const Subtitle = styled.h3`
  margin: 8px 0;
`;

const Contact = styled.p`
  margin: 2px 0;
`;

const ExLink = styled.a`
  display: block;
  text-decoration: none !important;
  margin: 2px 0;
`;

function Cookies() {
  return (
    <React.Fragment>
      <SEO
        title="Cookies"
        description={'Læs om vores cookie og privatlivspolitik'}
      />
      <Layout title="Cookies" withoutSeo>
        <Block>
          <Title>Cookie- og privatlivspolitik</Title>
          <Subtitle>Introduktion</Subtitle>
          <p>
            Når du besøger vores website indsamles der oplysninger om dig, som
            bruges til at tilpasse og forbedre vores indhold og til at øge
            værdien af de annoncer, der vises på siden. Hvis du ikke ønsker, at
            der indsamles oplysninger, bør du slette dine cookies{' '}
            <a
              href="https://minecookies.org/cookiehandtering/"
              target="_blank"
              rel="noreferrer"
            >
              (se vejledning)
            </a>{' '}
            og undlade videre brug af websitet. Nedenfor har vi uddybet, hvilke
            informationer der indsamles, deres formål og hvilke tredjeparter,
            der har adgang til dem.
          </p>
          <Subtitle>Cookies</Subtitle>
          <p>
            Websitet anvender ”cookies”, der er en tekstfil, som gemmes på din
            computer, mobil el. tilsvarende med det formål at genkende den,
            huske indstillinger, udføre statistik og målrette annoncer. Cookies
            kan ikke indeholde skadelig kode som f.eks. virus. <br />
            Det er muligt at slette eller blokere for cookies. Se vejledning:
            <br />
            <a
              href="http://minecookies.org/cookiehandtering"
              target="_blank"
              rel="noreferrer"
            >
              https://minecookies.org/cookiehandtering
            </a>{' '}
            <br />
            Hvis du sletter eller blokerer cookies vil annoncer kunne blive
            mindre relevante for dig og optræde hyppigere. Du kan desuden
            risikere at websitet ikke fungerer optimalt samt at der er indhold,
            du ikke kan få adgang til. <br />
            <br />
            Websitet indeholder cookies fra tredjeparter, der i varierende
            omfang kan omfatte:{' '}
            <ul>
              <li>Partner Ads</li>
            </ul>
          </p>
          <Subtitle style={{ margin: '8px 0 42px' }}>
            Personoplysninger
          </Subtitle>
          <Subtitle>Generelt</Subtitle>
          <p>
            Personoplysninger er alle slags informationer, der i et eller andet
            omfang kan henføres til dig. Når du benytter vores website indsamler
            og behandler vi en række sådanne informationer. Det sker f.eks. ved
            alm. tilgang af indhold, hvis du tilmelder dig vores nyhedsbrev,
            deltager i konkurrencer eller undersøgelser, registrerer dig som
            bruger eller abonnent, øvrig brug af services eller foretager køb
            via websitet.
            <br />
            <br /> Vi indsamler og behandler typisk følgende typer af
            oplysninger: Et unikt ID og tekniske oplysninger om din computer,
            tablet eller mobiltelefon, dit IP-nummer, geografisk placering, samt
            hvilke sider du klikker på (interesser). I det omfang du selv giver
            eksplicit samtykke hertil og selv indtaster informationerne
            behandles desuden: Navn, telefonnummer, e-mail, adresse og
            betalingsoplysninger. Det vil typisk være i forbindelse med
            oprettelse af login eller ved køb.
          </p>
          <Subtitle>Sikkerhed</Subtitle>
          <p>
            Vi har truffet tekniske og organisatoriske foranstaltninger mod, at
            dine oplysninger hændeligt eller ulovligt bliver slettet,
            offentliggjort, fortabt, forringet eller kommer til uvedkommendes
            kendskab, misbruges eller i øvrigt behandles i strid med
            lovgivningen.
          </p>
          <Subtitle>Formål</Subtitle>
          <p>
            Oplysningerne bruges til at identificere dig som bruger og vise dig
            de annoncer, som vil have størst sandsynlighed for at være relevante
            for dig, at registrere dine køb og betalinger, samt at kunne levere
            de services, du har efterspurgt, som f.eks. at fremsende et
            nyhedsbrev. Herudover anvender vi oplysningerne til at optimere
            vores services og indhold.
          </p>
          <Subtitle>Periode for opbevaring</Subtitle>
          <p>
            Oplysningerne opbevares i det tidsrum, der er tilladt i henhold til
            lovgivningen, og vi sletter dem, når de ikke længere er nødvendige.
            Perioden afhænger af karakteren af oplysningen og baggrunden for
            opbevaring. Det er derfor ikke muligt at angive en generel tidsramme
            for, hvornår informationer slettes.
          </p>
          <Subtitle>Videregivelse af oplysninger</Subtitle>
          <p>
            Data om din brug af websitet, hvilke annoncer, du modtager og evt.
            klikker på, geografisk placering, køn og alderssegment m.v.
            videregives til tredjeparter i det omfang disse oplysninger er
            kendt. Du kan se hvilke tredjeparter, der er tale om, i afsnittet om
            ”Cookies” ovenfor. Oplysningerne anvendes til målretning af
            annoncering.
            <br />
            <br />
            Vi benytter herudover en række tredjeparter til opbevaring og
            behandling af data. Disse behandler udelukkende oplysninger på vores
            vegne og må ikke anvende dem til egne formål.
            <br />
            <br />
            Videregivelse af personoplysninger som navn og e-mail m.v. vil kun
            ske, hvis du giver samtykke til det. Vi anvender kun databehandlere
            i EU eller i lande, der kan give dine oplysninger en tilstrækkelig
            beskyttelse.
          </p>
          <Subtitle>Indsigt og klager</Subtitle>
          <p>
            Du har ret til at få oplyst, hvilke personoplysninger, vi behandler
            om dig. Du kan desuden til enhver tid gøre indsigelse mod, at
            oplysninger anvendes. Du kan også tilbagekalde dit samtykke til, at
            der bliver behandlet oplysninger om dig. Hvis de oplysninger, der
            behandles om dig, er forkerte har du ret til at de bliver rettet
            eller slettet. Henvendelse herom kan ske til: jk@midear.dk Hvis du
            vil klage over vores behandling af dine personoplysninger, har du
            også mulighed for at tage kontakt til{' '}
            <a
              href="https://www.datatilsynet.dk/"
              target="_blank"
              rel="noreferrer"
            >
              Datatilsynet.
            </a>
          </p>
          <Subtitle>Samarbejde</Subtitle>
          <p>
            <ExLink href="https://billigform.dk" target="_blank">
              Billigform.dk
            </ExLink>
            <ExLink href="https://sovbedst.dk" target="_blank">
              Sovbedst.dk
            </ExLink>
            <ExLink href="https://malhjemme.dk" target="_blank">
              Malhjemme.dk
            </ExLink>
            <ExLink href="https://mincardio.dk" target="_blank">
              Mincardio.dk
            </ExLink>
            <ExLink href="https://lineout.dk" target="_blank">
              Lineout.dk
            </ExLink>
            <ExLink href="https://www.padelfam.dk" target="_blank">
              Padelfam.dk
            </ExLink>
            <ExLink href="https://www.sweep.menu" target="_blank">
              Sweep
            </ExLink>
            <ExLink href="https://www.charterferien.dk" target="_blank">
              Charterferien
            </ExLink>
            <ExLink href="https://pejsogbaal.dk" target="_blank">
              Pejs & Bål
            </ExLink>
            <ExLink href="https://www.zooticket.dk" target="_blank">
              Zooticket
            </ExLink>
          </p>
          <Subtitle>Udgiver</Subtitle>
          <p>
            Hjemmesiden er udviklet af
            <ExLink href="https://www.midear.dk" target="_blank">
              React konsulent
            </ExLink>
            <Contact>CVR.: 41270713</Contact>
            <Contact>Tlf. +45 24628694</Contact>
            <Contact>jk@midear.dk</Contact>
          </p>
        </Block>
      </Layout>
    </React.Fragment>
  );
}

export default Cookies;
